import { Options, Vue } from "vue-class-component";
import { IRuntimePermissionRequestAuthCodeParams } from "dingtalk-jsapi/api/runtime/permission/requestAuthCode";
import * as dd from "dingtalk-jsapi";
import API from "@/api/auth";
import router from "@/router";
import { DingAK } from "@/utils/axios";

@Options({
    name: "app-login-dingtalk",
})
export default class LoginDingtalk extends Vue {
    private isLoading = false;
    private authCode: any = null;
    public mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        that.isLoading = true;
        dd.ready(function () {
            dd.runtime.permission.requestAuthCode(<IRuntimePermissionRequestAuthCodeParams>{
                corpId: DingAK,
                onSuccess: (result: any) => {
                    that.authCode = result.code;
                    API.loginDingtalk({
                        authCode: that.authCode,
                    }).then((res: any) => {
                        that.$store.dispatch("auth/setUserInfo", res.data);
                        const userInfo = that.$store.getters["auth/userInfo"];
                        if (userInfo && userInfo.userIsOne && userInfo.userIsOne === 1) {
                            router.replace("/loginModPass");
                        } else {
                            router.replace("/");
                        }
                        that.isLoading = false;
                    });
                },
                onFail: (err: any) => {
                    //that.isLoading = false;
                    alert("dd error: " + JSON.stringify(err));
                },
            });
        });
    }
}
